
.dashCard{
    background-color: white;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: relative;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    height: 100% !important;
}

.dashCard h6{
    font-weight: bold;
}

.homeproperty{
    display: flex;
    justify-content: space-between;
}

.property{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.property span{
    font-size: 10px;
}

.property-type{
    font-weight: 300;
    color: #000;
    font-style: italic;   
}

.property-icon{
    color: red;
}

.property-icon:hover{
    color: #028599
}

.agente{
    /* border: 1px #028599 solid; */
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center !important;   
    margin: auto;
}

.base{
    background-color: orange !important;
    color: white;
    margin-bottom: 10px;
}

.advance{
    background-color: #028599 !important;
    color: white;
    margin-bottom: 10px;
}

.premium{
    background-color: #2E365F !important;
    color: white;
    margin-bottom: 10px;
}

.pricingbase{
    border-top: orange 8px solid;
}

.pricingadvance{
    border-top: #028599 8px solid;
}

.pricingpremium{
    border-top: #2E365F 8px solid;
}