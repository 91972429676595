@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500&display=swap');
*{
  /* background-color: #105f6c; */
  /* color: white; */
}
.section_1{
    background-color: #028599;
    /* background: rgb(2, 0, 36); */
    /* background: linear-gradient(90deg, rgba(2, 0, 36, 1)10%, rgba(0, 0, 255, 1)50%, rgba(0, 212, 255, 1)100%); */
    padding-top: 180px;
    height: 100%;
}

.sect_1_a{
    text-align: left;
}
.section_1{
    color: white;
}
.sect_1_a h1{
  /* background: linear-gradient(45deg, #028599, #af4261); */
  background: linear-gradient(45deg, white, #aaaaaa);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.section_1 p{
  font-size: medium;
  margin-bottom: 20px;
}

.section_2{
  background-color: #028599;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.textbrand{
  text-align: center;
  background: linear-gradient(45deg, white, #aaaaaa);
    background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
    font-size: 32px;
    margin-bottom: 20px;
}


.section_2 p{
  font-size: l;
  color: rgb(99, 96, 96);
  font-family: 'Poppins', sans-serif;
}

.section_2 h2{
  text-align: center;
  margin-top: 80px;
  color: #065fe4;
}

.serv{
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border: 1px #fff solid;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.serv span{
  text-align: center;
}

.serv h6{
  margin-bottom: 5px;
  margin-top: 5px;
}