.activeSlide{
    background-color: gold !important;
    color: #2a3bda !important;
    border-radius: 10px;
}

.slidebarbutton{
    color: gold;
}

.sidebarhead{
    display: flex;
}

.sidebardash{
    background: #2E365F;
}