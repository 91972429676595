.bg-gra-02 {
    background: -webkit-gradient(linear, left bottom, right top, from(#028499), to(#2E365F));
    background: -webkit-linear-gradient(bottom left, #028499 0%, #2E365F 100%);
    background: -moz-linear-gradient(bottom left, #028499 0%, #2E365F 100%);
    background: -o-linear-gradient(bottom left, #028499 0%, #2E365F 100%);
    background: linear-gradient(to top right, #028499 0%, #2E365F 100%);
  }

  .page-wrapper{
    min-height: 100vh;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
  }

  .rowform{
    background-color: white;
    border-radius: 3px;
  }