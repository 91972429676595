.agente{
    background-color: #028599;
    color: white;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: center;
    border-radius: 10px;
    
}

.agentered{
    background-color: orange;
    color: white;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: center;
    border-radius: 10px;
    margin-right: 5px;
}

.dasCard{
    background-color: white;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    height: 100%;
    margin: 0;
}

.dashCard h6{
    font-weight: bold;
}

.homeproperty{
    display: flex;
    justify-content: space-between;
}

.property{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.property span{
    font-size: 10px;
}

.property-type{
    font-weight: 300;
    color: #000;
    font-size: 14px;
    font-style: italic;   
}

.property-icon{
    color: #028599;
}

.property-icon:hover{
    color: red
}

.agent{
    border: 1px #028599 solid;
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    /* width: 100px; */
    justify-content: center;
    padding: 0px 10px 0px 10px;
}

.pimg{
    width: 100%;
    height: 180px;
    border-radius: 5px;
  }

  .agentup{
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: center;
    border: 1px #028599 solid;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .agentup:hover{
    background-color: #028599;
    color: white;
    cursor: pointer;
  }