.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.oneline{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whatsapp{
  -webkit-filter: drop-shadow(5px 5px 5px #111);
  filter: drop-shadow(5px 5px 5px #111);
}

.bigbtn{
  position: fixed;
  bottom: 10px;
  right: 13px;
  z-index: 1000;
}

@media screen and (max-width: 770px){
  .bigbtn{
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 1000;
  }
  .mopad{
    padding: 2px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
