.transactionHead{
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: hsl(230, 35%, 28%);
    margin-top: 10px;
    border-radius: 10px;
    color: white;
}

.transactionHead h5{
    font-weight: bold;
}

.dashCard{
    background-color: white;
    height: 150px;
    /* width: 90%; */
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashCard h6{
    font-weight: bold;
}

.bal{
    background-color: rgba(0, 255, 0, 0.3);
}
.with{
    background-color: rgba(255, 0, 0, 0.3);
}
.dep{
    background-color: rgba(0, 100, 255, 0.3);
}
.trans{
    background-color: rgba(255, 120, 0, 0.3);
}

.transactionForm{
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    margin-bottom: 10px;
}

.transactionForm h6{
    margin-bottom: 20px;
    font-weight: bold;
}

thead{
    background-color: hsl(230, 35%, 28%);
    color: white;
}