.cardhead{
    /* flex: 0.3; */
    background-color: gray;
    position: relative;
    z-index: 0;
    /* width: 100%; */
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    
}

.profileimg{
    width: 100%;
    /* height: 150px; */
    position: absolute;
    bottom: -45px;
    display: flex;
    justify-content: center;
    z-index: 1;
    border-radius: 100px;
    padding: 10px;
    
}

.profileimg img{
    border: 2px solid #2E365F;
    /* border-radius: 100px; */
}

.coverimage{
    height: 100%;
}

.profileContain{
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    margin-top: 30px;
}

.profileInfos{
    display: block;
    flex: 0.65;
}

.profilepageinfo{
    margin-top: 80px;
    text-align: center;
}

.profilepageinfo h3{
    font-weight: 600;
    color: #2E365F;
}

.profilepageinfo h6{
    font-size: smaller;
}

.profilepageinfo h4{
    font-weight: lighter !important;
    color: #aaa;
    margin-top: 20px;
}

.profilepageinfo h5{
    
}

.infosBox{
    background-color: white;
    /* height: 100%; */
    border-radius: 10px;
    padding-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.iputfield{
    background-color: rgb(246, 248, 254);
}

.infosBox h6{
    font-weight: bold;
}

.profileInfos h5{
    font-weight: bold;
}

.profileimg .editprofile{
    position: absolute;
    bottom: 10px;
    right: 35%;
    color: white;
    background-color: #2E365F;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editcover{
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background-color: #2E365F;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
    .profileContain{
        display: block;

    }
    .profileInfos{
        display: block;
        flex: 1;
        margin-top: 300px;
    }
    .profileimg .editprofile{
        position: absolute;
        bottom: 10px;
        right: 50%;
        color: white;
        background-color: #2E365F;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iputfield{
        width: 100%
    }
    
}